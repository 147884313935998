<template>
  <multiselect
    v-model="selectedSuggestion"
    class="input--wrapper multiselect--search"
    select-label=""
    deselect-label=""
    track-by="id"
    label="name"
    :searchable="true"
    :close-on-select="true"
    :options="suggestions"
    :loading="getSuggestionLoading"
    :internal-search="false"
    :multiple="false"
    :preserve-search="true"
    :placeholder="currentKeyword ? currentKeyword : $t('home.searchBarPlaceholder')"
    @search-change="onSearchChange"
    :class="{
      filled: currentKeyword,
      'no--selection': selectedSuggestion === null || selectedSuggestion.length === 0,
    }"
    :block-keys="['Enter']"
    ref="multiselectSearch"
    @open="onOpen"
  >
    <template slot="selection" slot-scope="{ values, remove, isOpen }">
      <span class="region--tag" v-for="(value, index) in values" :key="`region-tag-${index}`">
        <span class="region--tag-content">
          <span>{{ value.name }}</span>
          <span v-if="value.parents">
            <span v-for="(parent, index) in value.parents" :key="`tag-parent-${index}`">
              <span v-if="index < value.parents.length - 1">
                <span>{{ ', ' }}</span>
                <span>{{ parent }}</span>
              </span>
            </span>
          </span>
        </span>
      </span>
      <div
        class="region--selection-open"
        v-if="isOpen && values !== null && values.length > 0"
      ></div>
      <span
        class="region--search-term"
        v-if="!isOpen && currentKeyword && values !== null && values.length > 0"
      >
        {{ currentKeyword }}
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="d-flex align-center">
        <i
          class="ion-earth"
          v-if="props.option.category === 'city' || props.option.category === 'province'"
        ></i>
        <i class="icon icon--building icon--sm" v-if="props.option.category === 'listing'"></i>
        <span class="ml-4" v-html="props.option.name"></span>
      </div>
    </template>
    <template slot="noResult">
      <div>{{ $t('general.noSearchResults') }}</div>
    </template>
    <template slot="noOptions">
      <div>{{ $t('general.noSearchOptions') }}</div>
    </template>
  </multiselect>
</template>

<script>
import { mapState } from 'vuex';
import LodashMixin from '@/mixins/lodash';

import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  mixins: [LodashMixin],
  components: {
    Multiselect,
  },
  data: () => ({
    suggestions: [],
    getSuggestionLoading: false,
    selectedSuggestionData: null,
    lockSearchTerm: false,
  }),
  computed: {
    ...mapState({
      listingType: (state) => state.v2.search.listingType,
      searchType: (state) => state.v2.search.searchType,
      searchTermId: (state) => state.v2.search.searchTermId,
      searchTermCategory: (state) => state.v2.search.searchTermCategory,
      isOfficialPartner: (state) => state.v2.search.isOfficialPartner,
    }),
    currentKeyword: {
      get() {
        return this.$store.state.v2.search.currentKeyword;
      },
      set(value) {
        this.$store.commit('v2/search/SET_CURRENT_KEYWORD', value);
      },
    },
    searchTerm: {
      get() {
        return this.$store.state.v2.search.searchTerm;
      },
      set(value) {
        this.$store.commit('v2/search/SET_SEARCH_TERM', value);
      },
    },
    selectedSuggestion: {
      get() {
        return this.selectedSuggestionData;
      },
      set(value) {
        if (value) {
          this.selectedSuggestionData = value;
          this.currentKeyword = value.name;
          this.searchTerm = value.name;
          this.lockSearchTerm = true;
          this.$store.commit('v2/search/SET_SEARCH_TERM_ID', value.id);
          this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', value.category);
          this.$emit('selected');
        } else {
          this.clearAll();
        }
      },
    },
  },
  mounted() {
    this.debouncedQuery = this.debounce(this.getSuggestions, 500);
    this.currentKeyword = this.searchTerm;
    if (this.searchTermId) {
      this.selectedSuggestionData = {
        id: parseInt(this.searchTermId),
        name: this.searchTerm,
        category: this.searchTermCategory,
      };
    }
  },
  methods: {
    async getSuggestions() {
      try {
        this.getSuggestionLoading = true;
        let response = await this.$store.dispatch('v2/search/getSuggestions', {
          query: this.searchTerm,
          listingType: this.listingType,
          searchType: this.isOfficialPartner === true ? 3 : this.searchType,
        });
        if (response.type === 'success') {
          this.suggestions = response.data;
        }
      } finally {
        this.getSuggestionLoading = false;
      }
    },
    onSearchChange(searchQuery) {
      this.getSuggestionLoading = true;
      this.currentKeyword = searchQuery;
      if (!this.lockSearchTerm) {
        this.searchTerm = searchQuery;
        this.selectedSuggestionData = null;
        this.$store.commit('v2/search/SET_SEARCH_TERM_ID', null);
        this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', null);
      } else {
        this.lockSearchTerm = false;
      }
      this.debouncedQuery(searchQuery);
    },
    onOpen() {
      this.$refs.multiselectSearch.search = this.currentKeyword ? this.currentKeyword : '';
    },
    clearAll() {
      this.selectedSuggestionData = null;
      this.currentKeyword = null;
      this.searchTerm = null;
      this.$store.commit('v2/search/SET_SEARCH_TERM_ID', null);
      this.$store.commit('v2/search/SET_SEARCH_TERM_CATEGORY', null);
    },
  },
};
</script>

<style scoped></style>
